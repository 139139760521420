<template>
  <!-- Header section -->
  <el-row :gutter="10">
    <el-col :span="20" :offset="2">
      <header class="page-header">
          <el-row :gutter="20" align="middle">
            <el-col :span="12">
                <h2>Цагийн хуваарь</h2>
            </el-col>
          </el-row>
      </header>
      <!-- Core content -->
      <div class="panel tabled" v-loading="loading">
        <div class="panel-content">
          <el-tabs @tab-click="generateWorkTime">
            <el-tab-pane v-for="(supplierData, indexSupplierData) in suppliers" :key="indexSupplierData" :label="supplierData.supplier_monName">
              <div class="pl40 pr40 mt20 table-filter mb40">
                <el-row :gutter="10">
                  <el-col :span="14">
                    <el-form>
                      <el-form-item v-for="(day, indexDay) in week" :key="indexDay">
                        <el-row :gutter="20">
                          <el-col :span="4">
                            <span class="mr20">{{day.day}}</span>
                          </el-col>
                          <el-col :span="16">
                            <el-time-select
                              :disabled="day.is_weekend"
                              size="mini"
                              placeholder="Нээх цаг"
                              v-model="day.start_time">
                            </el-time-select>
                            <el-time-select
                              class="ml5"
                              size="mini"
                              :disabled="day.is_weekend"
                              placeholder="Хаах цаг"
                              v-model="day.end_time">
                            </el-time-select>
                          </el-col>
                          <el-col :span="4">
                            <el-tooltip class="item" effect="light" content="Амрах өдөр" placement="top">
                              <el-checkbox class="ml10" v-model="day.is_weekend" @change="isWeekend(day)"></el-checkbox>
                            </el-tooltip>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-form>
                    <!-- <el-table :data="events">
                      <el-table-column label="Өдөр">
                        <template slot-scope="scope">
                          <span>{{scope.row.date}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="comments" label="Тайлбар"></el-table-column>
                    </el-table> -->
                  </el-col>
                  <!-- <el-col :span="10">
                    <vue-scheduler
                      :min-date="null"
                      :max-date="null"
                      :labels="{
                          today: 'Өнөөдөр',
                          back: 'Өмнөх',
                          next: 'Дараахь',
                          month: 'Сар',
                          week: '7 хоног',
                          day: 'Өдөр',
                          all_day: 'Бүх өдөр'
                      }"
                      :time-range="[10, 20]"
                      :available-views="['month']"
                      :initial-date="new Date()"
                      initial-view="month"
                      use12
                      :show-time-marker="true"
                      :show-today-button="true"
                      :event-dialog-config="dialogConfig"
                      :events="events"
                      @event-created="eventCreated"
                      @event-clicked="eventClicked"
                      :event-display="eventDisplay"
                      />
                  </el-col> -->
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="text-right border-top pt20">
        <el-button type="success" @click="updateSupplier">Хадгалах</el-button>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import services from '../../../helpers/services'
export default {
  name: 'Schedule',

  mounted () {
    this.getSuppliers()
  },

  data () {
    return {
      tabPosition: 'left',
      loading: false,
      selectSupplier: null,
      suppliers: [],
      events: [],
      visibleDialog: false,
      dialogConfig: {
        title: 'Амрах өдөр',
        createButtonLabel: 'Нэмэх',
        enableTimeInputs: false,
        fields: [
          {
            name: 'comments',
            label: 'Шалтгаан'
          }
        ]
      },
      week: [
        {
          day: 'Даваа',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Мягмар',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Лхагва',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Пүрэв',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Баасан',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Бямба',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Ням',
          is_weekend: false,
          start_time: null,
          end_time: null
        }
      ]
    }
  },

  methods: {
    isWeekend (day) {
      if (day.is_weekend === true) {
        day.start_time = null
        day.end_time = null
      }
    },
    getSuppliers () {
      const query = '?search_text=' + '' + '&from=' + 0 + '&size=' + 100 + '&field=' + '' + '&sort=' + ''
      this.loading = true
      services.getSuppliers(query).then(response => {
        if (response.status === 'success') {
          this.suppliers = response.data
          if (this.suppliers.length > 0) {
            this.selectSupplier = this.suppliers[0]
          }
          this.loading = false
          this.generateWorkTime(this.selectSupplier.supplier_monName)
        } else {
          this.loading = false
        }
      })
    },

    generateWorkTime (tab, event) {
      this.suppliers.forEach(supplier => {
        if (tab.label === supplier.supplier_monName) {
          this.selectSupplier = supplier
        }
      })
      if (Object.prototype.hasOwnProperty.call(this.selectSupplier, 'work_time')) {
        this.week.forEach(day => {
          if (day.day === 'Даваа') {
            if (this.selectSupplier.work_time.monday.start_time === null || this.selectSupplier.work_time.monday.end_time === null) {
              day.is_weekend = true
            }
            day.start_time = this.selectSupplier.work_time.monday.start_time
            day.end_time = this.selectSupplier.work_time.monday.end_time
          } else if (day.day === 'Мягмар') {
            if (this.selectSupplier.work_time.tuesday.start_time === null || this.selectSupplier.work_time.tuesday.end_time === null) {
              day.is_weekend = true
            }
            day.start_time = this.selectSupplier.work_time.tuesday.start_time
            day.end_time = this.selectSupplier.work_time.tuesday.end_time
          } else if (day.day === 'Лхагва') {
            if (this.selectSupplier.work_time.wednesday.start_time === null || this.selectSupplier.work_time.wednesday.end_time === null) {
              day.is_weekend = true
            }
            day.start_time = this.selectSupplier.work_time.wednesday.start_time
            day.end_time = this.selectSupplier.work_time.wednesday.end_time
          } else if (day.day === 'Пүрэв') {
            if (this.selectSupplier.work_time.thursday.start_time === null || this.selectSupplier.work_time.thursday.end_time === null) {
              day.is_weekend = true
            }
            day.start_time = this.selectSupplier.work_time.thursday.start_time
            day.end_time = this.selectSupplier.work_time.thursday.end_time
          } else if (day.day === 'Баасан') {
            if (this.selectSupplier.work_time.friday.start_time === null || this.selectSupplier.work_time.friday.end_time === null) {
              day.is_weekend = true
            }
            day.start_time = this.selectSupplier.work_time.friday.start_time
            day.end_time = this.selectSupplier.work_time.friday.end_time
          } else if (day.day === 'Бямба') {
            if (this.selectSupplier.work_time.saturday.start_time === null || this.selectSupplier.work_time.saturday.end_time === null) {
              day.is_weekend = true
            }
            day.start_time = this.selectSupplier.work_time.saturday.start_time
            day.end_time = this.selectSupplier.work_time.saturday.end_time
          } else {
            if (this.selectSupplier.work_time.sunday.start_time === null || this.selectSupplier.work_time.sunday.end_time === null) {
              day.is_weekend = true
            }
            day.start_time = this.selectSupplier.work_time.sunday.start_time
            day.end_time = this.selectSupplier.work_time.sunday.end_time
          }
        })
      } else {
        this.week.forEach(day => {
          day.start_time = null
          day.end_time = null
          day.is_weekend = false
        })
      }
    },

    updateSupplier () {
      this.loading = true
      const body = this.selectSupplier
      body.work_time = this.generateWorkTimeBody()
      if (this.selectSupplier.supplier_id !== '') {
        services.updateSupplier(body).then(data => {
          if (data.status === 'success') {
            this.alertReporter('Амжилттай', data.message, 'success')
            this.getSuppliers()
            this.loading = false
          } else if (data.status === 'error') {
            this.alertReporter('Алдаа', data.message, 'error')
            this.loading = false
          }
        })
      }
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },

    generateWorkTimeBody () {
      const workingTime = {}
      this.week.forEach(day => {
        if (day.day === 'Даваа') {
          workingTime.monday = {
            end_time: day.end_time,
            start_time: day.start_time
          }
        } else if (day.day === 'Мягмар') {
          workingTime.tuesday = {
            end_time: day.end_time,
            start_time: day.start_time
          }
        } else if (day.day === 'Лхагва') {
          workingTime.wednesday = {
            end_time: day.end_time,
            start_time: day.start_time
          }
        } else if (day.day === 'Пүрэв') {
          workingTime.thursday = {
            end_time: day.end_time,
            start_time: day.start_time
          }
        } else if (day.day === 'Баасан') {
          workingTime.friday = {
            end_time: day.end_time,
            start_time: day.start_time
          }
        } else if (day.day === 'Бямба') {
          workingTime.saturday = {
            end_time: day.end_time,
            start_time: day.start_time
          }
        } else if (day.day === 'Ням') {
          workingTime.sunday = {
            end_time: day.end_time,
            start_time: day.start_time
          }
        }
      })
      return workingTime
    },

    eventDisplay (event) {
      return event.comments
    },
    eventCreated (event) {
    },
    eventClicked (event) {
    }
  }
}
</script>

<style>
.v-cal-header__title {
  margin-top: 10px !important;
}
.v-cal-header {
  padding: 0px !important
}
.v-cal-content.v-cal-content--month .v-cal-days .v-cal-day {
  min-height: 60px !important;
}
</style>
